@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
}

button {
	border-radius: 6px !important;
}

li {
	list-style: none;
}

.login-from {
	padding: 20px;
	border: 1px solid #dbdbdb;
}

.sign-in-container h1,
.sign-up-container h1 {
	font-weight: bold;
	margin: 0;
}

.sign-in-container h2,
.sign-up-container h2 {
	text-align: center;
}

.sign-in-container p,
.sign-up-container p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

.sign-in-container span,
.sign-up-container span {
	font-size: 14px;
}

.sign-in-container a,
.sign-up-container a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

.forgotpassword:hover {
	color: #FF4B2B;
}

.sign-in-container button,
.sign-up-container button {
	border-radius: 20px;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
	margin-top: 15px;
}

.sign-in-container button:hover,
.sign-up-container button:hover {
	background-color: #FF7558;
	color: #FFFFFF;
	transform: scale(1.05);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.sign-in-container button:active,
.sign-up-container button:active {
	transform: scale(0.95);
}

.sign-in-container button:focus,
.sign-up-container button:focus {
	outline: none;
}

.sign-in-container button.forgotpassword {
	background-color: transparent;
	border: none;
	color: #333;
	text-transform: capitalize;
	padding: 0;
	font-size: 14px;
	letter-spacing: normal;
}

.modalbutton {
	float: right;
	margin-top: 15px;
}

.overlay-container button {
	border-radius: 20px;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}

.modal-title.h4 {
	font-size: 18px !important;
}

.overlay-container button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

.sign-in-container form,
.sign-up-container form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 25%;
	height: 100%;
	text-align: center;
}

input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
	border-radius: 8px;
	color: #333;
}

.password-container {
	position: relative;
	width: 100%;
}

.eye-icon {
	position: absolute;
	right: 14px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}

textarea {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}

.radiogroup {
	display: flex;
}

.radiogroup label {
	display: flex;
	padding: 10px 10px 10px 0;
}

.radiogroup label input {
	line-height: normal;
	margin: 0 5px 0 0;
}

.sign-up-container select {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
	border-radius: 8px;
	color: #333;
	appearance: none;
	-webkit-appearance: none;
}

.select-container {
	position: relative;
	width: 100%;
}

.down-arrow {
	position: absolute;
	right: 14px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	transition: transform 0.1s ease;
}

.down-arrow.open {
	transform: translateY(-50%) rotate(180deg);
	/* Rotate arrow */
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.container.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {

	0%,
	49.99% {
		opacity: 0;
		z-index: 1;
	}

	50%,
	100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.container.right-panel-active .overlay-container {
	transform: translateX(-100%);
}

.overlay {
	background: #FF416C;
	background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
	background: linear-gradient(to right, #FF4B2B, #FF416C);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
	transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay-left {
	transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.social-container {
	margin: 20px 0;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

.social-container a:hover {
	background-color: rgba(255, 75, 43, 0.1);
	border-color: #FF4B2B;
	color: #FF4B2B;
}

.homepage {
	padding: 15px;
	padding-top: 50px;
}

.homepage h1 {
	text-align: center;
}

button {
	border: none;
	background-color: #FF4B2B;
	padding: 5px 16px;
	color: #fff;
}

.homepage button {
	float: right;
	font-weight: 500;
	transition: all 0.3s ease;
}

.homepage button:hover {
	transform: translateY(-3px);
	box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

.homepage button.sendinvite {
	background: linear-gradient(45deg, #F39C12, #FF4B2B);
	color: #FFFFFF;
	border-radius: 8px;
	cursor: pointer;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease;
}

.homepage button.sendinvite:hover {
	background-color: #E67E22;
	transform: translateY(-3px);
	box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

.homepage button.sendinvite:active {
	background-color: #D35400;
	transform: translateY(1px);
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.homepage h3 {
	font-size: 18px;
	margin-bottom: 20px;
	width: 50%;
	display: inline-block;
}

.homepage .tag-error {
	color: red;
	margin-top: 8px;
	margin-bottom: 0;
}

.homepage .tag-success {
	color: green;
	font-size: 14px;
	margin-top: 10px;
}

.tag-area {
	padding: 1rem;
	border: 2px solid #605f6f;
	border-radius: 5px;
	transition: all 0.2s;
	cursor: text;
	display: flex;
	flex-direction: column;
	position: relative;
	max-width: 100%;
}

.label {
	position: absolute;
	padding: 0 0.3rem;
	color: #adadad;
	top: 20px;
	left: 12px;
	transition: all 0.2s ease;
}

.label-active {
	top: -10px;
	font-size: 13px;
	background-color: #605f6f;
	color: #fff;
	padding: 0 5px;
	border-radius: 5px;
}

.tag-list {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 8px;
	padding: 0;
	margin: 0;
}

.tag {
	background: linear-gradient(to right, #FF4B2B, #FF416C);
	border-radius: 100px;
	color: #fff;
	display: flex;
	align-items: center;
	padding: 5px 12px;
	font-size: 14px;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.input-container {
	display: flex;
	align-items: center;
	flex: 1;
}

.tag-input {
	border: none;
	font-size: 16px;
	outline: none;
	background: transparent;
	flex-grow: 1;
	min-width: 120px;
	max-width: 100%;
	padding: 5px;
}

.cross {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	margin-left: 8px;
	color: #fff;
	background: #414141;
	width: 18px;
	height: 18px;
	border-radius: 50%;
}

.cross:hover {
	background: #818181;
}

.sendinvite {
	margin-top: 10px;
	padding: 6px 12px;
	background: #605f6f;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 14px;
	display: inline-block;
	align-self: flex-end;
  }

.error.login-error {
	text-align: left;
	width: 100%;
	font-size: 14px;
	color: #f00;
}

.forgotpasswordform {
	padding: 15px;
	border: 1px solid #cecece;
	display: inline-block;
	margin-top: 20%;
	border-radius: 7px;
}

.forgotpasswordform h3 {
	font-size: 18px;
}

.forgotpasswordform input.form-control {
	display: inline-block;
}

.forgotpasswordform .backbtn {
	border: none;
	padding: 5px 16px;
	color: #fff;
	background-color: #adadad;
	margin-top: 15px;
	display: inline-block;
	border-radius: 10px;
}

@media screen and (max-width: 992px) {

	.sign-in-container form,
	.sign-up-container form {
		padding: 0 18%;
	}
}

@media screen and (max-width: 767px) {
	.overlay-container {
		position: absolute;
		bottom: 0;
		left: 0px;
		width: 100%;
		height: 35%;
		overflow: hidden;
		transition: transform 0.6s ease-in-out;
		z-index: 100;
	}

	.container.right-panel-active .overlay-container {
		transform: inherit;
	}

	.sign-in-container form,
	.sign-up-container form {
		background-color: #FFFFFF;
		display: inherit;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 8% 16%;
		height: 100%;
		text-align: center;
	}

	.sign-in-container {
		left: 0;
		width: 100%;
		z-index: 2;
	}

	.sign-up-container {
		left: 0;
		width: 100%;
		z-index: 2;
	}

	.container.right-panel-active .sign-up-container {
		/* transform: translateX(100%); */
		transform: inherit;
		opacity: 1;
		z-index: 5;
		animation: show 0.6s;
	}

	.form-container {
		top: 35%;
	}

	.sign-in-container button,
	.sign-up-container button {
		padding: 12px 35px;
	}

	.tag-area .tag-input {
		font-size: 16px;
		padding: 0.4rem;
	}
}

@media screen and (max-width: 484px) {
	.homepage {
		padding: 10px;
	}

	.homepage h1,
	.homepage h3 {
		font-size: 16px;
		text-align: center;
		margin-bottom: 10px;
		width: 100%;
	}

	.homepage h3 {
		font-size: 14px;
	}

	.homepage button {
		width: 100%;
		margin: 5px 0;
		margin-right: 0 !important;
	}

	.tag-area {
		padding: 0.8rem;
		font-size: 16px;
		margin-top: 8px;
	}

	.tag-area .tag {
		font-size: 14px;
		padding-left: 0.5rem;
	}
}

/* User details in tree structure */

.tree-heading {
	font-size: 18px;
	margin-bottom: 20px;
	margin-top: 20px;
}

.tree-node {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	margin-top: 40px;
}

.node {
	position: relative;
	/* background-color: #FAE3E0; */
	border: 2px solid #FF4B2B;
	border-radius: 10px;
	padding: 10px 20px;
	/* margin: 5px; */
	text-align: center;
	color: #FF4B2B;
	font-weight: bold;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	position: relative;
	transition: transform 0.3s;
	white-space: nowrap;
	/* overflow: hidden;
	text-overflow: ellipsis; */
}

.node-content {
	transition: transform 0.3s;
}

.node-content p {
	margin: 0;
}

.node:hover .node-content {
	transform: scale(1.05);
}

.node:hover {
	background-color: #FF4B2B;
	color: #fff;
	/* transform: scale(1.05); */
	cursor: pointer;
}

.tree-node .node::before {
	content: '';
	position: absolute;
	top: -8px;
	left: calc(50% - 4px);
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 8px solid #605f6f;
	width: 0;
	height: 0;
	z-index: 2;
}

.tree-node.root-node>.node::before {
	content: none !important;
}

.tree-children {
	display: flex;
	justify-content: center;
	/* align-items: center; */
	position: relative;
	padding-top: 11px;
}

.tree-children::before {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	border-left: 2px solid #605f6f;
	width: 0;
	height: 20px;
}

.tree-node .tree-children .tree-node {
	flex: 1;
	position: relative;
}

.tree-node .tree-children .tree-node::before {
	content: '';
	position: absolute;
	top: -25px;
	left: 50%;
	/* transform: translateX(-50%); */
	border-left: 2px solid #605f6f;
	height: 25px;
	z-index: -1;
}

.tree-node::after {
	content: '';
	position: absolute;
	top: -25px;
	right: 50%;
	border-top: 2px solid #605f6f;
	width: 51%;
	border-left: 2px solid #605f6f;
}

.tree-children .tree-node {
	padding: 0 15px;
}

.tree-node::before {
	right: auto;
	left: 50%;
	border-left: 2px solid #605f6f;
	content: '';
	position: absolute;
	top: 0;
	right: 50%;
	border-top: 2px solid #605f6f;
	width: 51%;
	height: 10px;
}

.root-node::before,
.root-node::after {
	display: none;
}

.tree-children::after {
	content: '';
	position: absolute;
	top: 19px;
	left: calc(50% - 4px);
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 8px solid #605f6f;
	width: 0;
	height: 0;
}

.single-child .tree-node::after,
.single-child .tree-node::before,
.single-child .tree-node .node::before,
.single-child::after {
	display: none;
}

.single-child .tree-node {
	margin-top: 15px;
}

.arrow-button {
	background-color: #e6e6e6;
	border: none;
	color: #FF4B2B;
	font-size: 25px;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 50% !important;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: background-color 0.3s ease;
	margin: 50px 15px;
}

.arrow-button:hover {
	background-color: #FF7050;
	color: #FFFFFF;
}

.more-button button {
	background-color: #fc7b5e;
	color: white;
	border: 1px solid #FF4B2B;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.3s ease, box-shadow 0.3s ease;
	margin: 15px 0;
}

.more-button button:hover {
	background-color: #FF4B2B;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-button button {
	background-color: #fc7b5e;
	color: white;
	border: 1px solid #FF4B2B;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.3s ease, box-shadow 0.3s ease;
	margin: 15px 0;
}

.close-button button:hover {
	background-color: #FF4B2B;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.success-message {
	color: #155724;
	background-color: #d4edda;
	border: 1px solid #c3e6cb;
	padding: 12px 20px;
	margin: 15px 0;
	border-radius: 6px;
	font-weight: 500;
	font-size: 1em;
	font-family: Arial, sans-serif;
	text-align: center;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.error-message {
	color: #721c24;
	background-color: #f8d7da;
	border: 1px solid #f5c6cb;
	padding: 12px 20px;
	margin: 15px 0;
	border-radius: 6px;
	font-weight: 500;
	font-size: 1em;
	font-family: Arial, sans-serif;
	text-align: center;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chart-box-section {
	background: #fff;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
	border: 1px solid #d1d5db;
}

.chart-box-section .user-name {
	font-size: 16px;
	font-weight: 700;
	color: #1d2433;
	margin-bottom: 5px;
}

.chart-box-section .user-title {
	font-size: 14px;
	font-weight: 400;
	color: #4a505c;
}

.links-wrapper path {
	stroke: #FF4B2B !important;
	stroke-width: 1.2px;
}

/* Loader CSS */
.loader-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(255, 255, 255, 0.7);
	backdrop-filter: blur(10px);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2000;
}

.loader {
	border: 8px solid #f3f3f3;
	border-top: 8px solid #FF4B2B;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}